import * as Herz from '@rushplay/herz'
import * as Hooks from './hooks'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

export function GameBanner(props) {
  const translate = Herz.I18n.useTranslate()
  const url = translate('game-banner.redirect-url')
  const backgroundImageDesktop = Hooks.useImgproxyUrl(
    translate('game-banner.background-image-desktop'),
    {extension: 'png', resizingWidth: 160},
    'babel-fisk'
  )
  const backgroundImageMobile = Hooks.useImgproxyUrl(
    translate('game-banner.background-image-mobile'),
    {extension: 'png', resizingWidth: 250},
    'babel-fisk'
  )

  if (!url) {
    return null
  }

  return (
    <ThemeUi.Box
      as="a"
      href={url}
      target="_blank"
      sx={{
        width: ['250px', '160px'],
        height: ['250px', '600px'],
        minHeight: '250px',
        my: ['15px', 'auto'],
        mx: ['auto', '30px'],
        backgroundImage: [
          `url(${backgroundImageMobile})`,
          `url(${backgroundImageDesktop})`,
        ],
        backgroundSize: 'cover',
        backgroundColor: 'nav-background',
        ...props.sx,
      }}
    />
  )
}

GameBanner.propTypes = {
  sx: PropTypes.object,
}
Herz.I18n.Loader.preload(
  [
    'game-banner.background-image-desktop',
    'game-banner.background-image-mobile',
    'game-banner.redirect-url',
  ],
  GameBanner
)
