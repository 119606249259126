import * as Configuration from './configuration'
import * as Constants from './constants'
import * as Herz from '@rushplay/herz'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Session from './session'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'
import * as Urql from 'urql'

import {GameBanner} from './game-banner'
import {GamePopup} from './game-popup'

const getGame = `
  query Game($id: ID!) {
    game(id: $id) {
      id
      key
      tags
    }
  }
`

function useGameUrl(gameId) {
  const clientType = ReactRedux.useSelector((state) =>
    Configuration.getClientType(state.configuration)
  )
  const language = ReactRedux.useSelector((state) =>
    Configuration.getLanguage(state.configuration)
  )
  const gameServerUrl = ReactRedux.useSelector((state) =>
    Configuration.getGameServerUrl(state.configuration)
  )
  const dpr = ReactRedux.useSelector((state) =>
    Configuration.getDevicePixleRatio(state.configuration)
  )

  const path = new URL(`/games/${gameId}`, gameServerUrl)
  path.search = new URLSearchParams({
    client_type: clientType,
    language: language || '',
    mode: 'classic',
    env: 'stg',
    brand: 'casinome',
    dpr,
  }).toString()

  return R.equals(clientType, Constants.ClientType.UNKNOWN) ? null : path
}

export function GameLauncher() {
  const ref = React.useRef(null)
  const location = ReactRouterDom.useLocation()
  const history = ReactRouterDom.useHistory()
  const {gameId} = ReactRouterDom.useParams()
  const authenticated = ReactRedux.useSelector((state) =>
    Session.isAuthenticated(state.session)
  )

  const [response] = Urql.useQuery({
    query: getGame,
    variables: {id: gameId},
  })

  const game = R.pathOr({}, ['game'], response.data)

  const translate = Herz.I18n.useTranslate(
    () => [`game-tile.${game.key}.title`],
    [game.key]
  )

  if (!authenticated && !response.fetching && !R.includes('demo', game.tags)) {
    history.push('/?login=me')
  }

  const title =
    !response.fetching & !response.error
      ? translate(`game-tile.${game.key}.title`)
      : ' '

  const gameUrl = useGameUrl(gameId)

  React.useEffect(() => {
    function gameEventListener(event) {
      const action = event.data || {}

      switch (action.type) {
        case '@rushplay/gamer/GAME_CLOSED': {
          return history.push('/')
        }
      }
    }

    window.addEventListener('message', gameEventListener)
    return () => {
      window.removeEventListener('message', gameEventListener)
    }
  }, [location, history])

  if (response.fetching) {
    return (
      <ThemeUi.Grid
        sx={{
          placeItems: 'center',
          flex: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <ThemeUi.Spinner />
      </ThemeUi.Grid>
    )
  }

  return (
    <ThemeUi.Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflowY: 'auto',
        backgroundColor: 'black',
      }}
    >
      <ThemeUi.Heading
        as="h1"
        sx={{
          flexShrink: 0,
          fontSize: 1,
          color: 'rgba(255, 255, 255, 0.45)',
          backgroundColor: 'footer-background',
          py: 0,
          px: 1,
        }}
      >
        {title}
      </ThemeUi.Heading>
      <ThemeUi.Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: ['column', 'row'],
          flex: 1,
          border: 'none',
        }}
      >
        <GameBanner sx={{display: ['none', 'block']}} />
        <ThemeUi.Box
          as="iframe"
          sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts"
          src={gameUrl}
          ref={ref}
          title={title}
          sx={{
            width: '100%',
            minHeight: ['90%', null],
            flex: 1,
            backgroundColor: 'black',
            border: 'none',
          }}
        />
        <GameBanner />
      </ThemeUi.Box>
      {!authenticated ? (
        <Suspense.Boundary fallback={null}>
          <GamePopup />
        </Suspense.Boundary>
      ) : null}
    </ThemeUi.Box>
  )
}

// For @loadable/components
export default GameLauncher
