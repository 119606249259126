import * as Herz from '@rushplay/herz'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import {Banner} from './banner'
import {CasinoCatalog} from './casino-catalog'
import {HtmlContent} from './html-content'

export function LandingPage() {
  const translate = Herz.I18n.useTranslate()

  return (
    <ThemeUi.Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Banner />
      <CasinoCatalog />
      <ThemeUi.Box
        sx={{
          mx: 'auto',
          maxWidth: 'container.lg',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          pb: 5,
          px: [1, 2],
        }}
      >
        <HtmlContent>{translate('landing-page.seo-content')}</HtmlContent>
      </ThemeUi.Box>
    </ThemeUi.Box>
  )
}

// For @loadable/components
export default LandingPage

Herz.I18n.Loader.preload(['landing-page.seo-content'], LandingPage)
