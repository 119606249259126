import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as Pwa from './pwa'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as ReactSpring from 'react-spring'
import * as Session from './session'
import * as ThemeUi from 'theme-ui'

import {Logotype} from './logotype'
import PropTypes from 'prop-types'

function DropdownMenu(props) {
  const translate = Herz.I18n.useTranslate()
  const pwa = Pwa.useContext()

  const transitions = ReactSpring.useTransition(props.visible, null, {
    from: {
      opacity: 0,
      transform: 'translate3d(0, -100%, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0, 0%, 0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0, -100%, 0)',
    },
  })

  function handlePwaInstall() {
    pwa.onInstall()
    props.onNavigate()
  }

  return transitions.map(
    ({item, key, props: styleProps}) =>
      item && (
        <ThemeUi.Box
          as={ReactSpring.animated.div}
          key={key}
          style={styleProps}
          sx={{
            zIndex: '-1',
            position: 'absolute',
            right: '0px',
            top: '56px',
            p: 3,
            width: ['100%', 'auto'],
            backgroundColor: 'static-white',
            borderBottomLeftRadius: 1,
            borderBottomRightRadius: 1,
            boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.75)',
          }}
        >
          <ThemeUi.Box
            as="nav"
            sx={{
              display: 'grid',
              gridGap: 2,
            }}
          >
            <ThemeUi.Link
              to="/how-to-play"
              as={ReactRouterDom.Link}
              variant="dropdownNav"
              onClick={props.onNavigate}
            >
              {translate('footer.how-to-play')}
            </ThemeUi.Link>
            <ThemeUi.Link
              href="mailto:support@casitabifree.com"
              variant="dropdownNav"
              onClick={props.onNavigate}
            >
              {translate('footer.contact-us')}
            </ThemeUi.Link>
            <ThemeUi.Link
              as={ReactRouterDom.Link}
              to="/terms-and-conditions"
              variant="dropdownNav"
              onClick={props.onNavigate}
            >
              {translate('footer.terms-and-conditions')}
            </ThemeUi.Link>
            <ThemeUi.Link
              as={ReactRouterDom.Link}
              to="/privacy-policy"
              variant="dropdownNav"
              onClick={props.onNavigate}
            >
              {translate('footer.privacy-policy')}
            </ThemeUi.Link>
            {pwa.visible && (
              <ThemeUi.Link variant="dropdownNav" onClick={handlePwaInstall}>
                {translate('pwa.install')}
              </ThemeUi.Link>
            )}
          </ThemeUi.Box>
        </ThemeUi.Box>
      )
  )
}

DropdownMenu.propTypes = {
  visible: PropTypes.bool,
  onNavigate: PropTypes.func.isRequired,
}

Herz.I18n.Loader.preload(
  [
    'footer.how-to-play',
    'footer.contact-us',
    'footer.privacy-policy',
    'footer.terms-and-conditions',
    'pwa.install',
  ],
  DropdownMenu
)

export function Topbar() {
  const [menuVisible, setMenuVisible] = React.useState()
  const translate = Herz.I18n.useTranslate()

  const authenticated = ReactRedux.useSelector((state) =>
    Session.isAuthenticated(state.session)
  )

  return (
    <ThemeUi.Box
      as="header"
      sx={{
        height: '56px',
        backgroundColor: 'nav-background',
        color: 'static-white',
        width: '100%',
        display: 'flex',
        position: 'sticky',
        top: '-1px',
        left: '0px',
        right: '0px',
        zIndex: 1,
        px: 1,
      }}
    >
      <ThemeUi.Box
        sx={{
          position: 'relative',
          mx: 'auto',
          maxWidth: 'container.lg',
          width: '100%',
        }}
      >
        <DropdownMenu
          visible={menuVisible}
          onNavigate={() => setMenuVisible(!menuVisible)}
        />
        <ThemeUi.Box
          sx={{
            backgroundColor: 'nav-background',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Logotype />
          <ThemeUi.Box
            sx={{
              display: 'grid',
              gridGap: 1,
              gridTemplateColumns: ['1fr 29px', '1fr 32px'],
            }}
          >
            {authenticated ? (
              <ReactRouterDom.Link to="/logout">
                <ThemeUi.Button
                  variant="muted"
                  sx={{
                    py: 0,
                  }}
                >
                  {translate('topbar.logout')}
                </ThemeUi.Button>
              </ReactRouterDom.Link>
            ) : (
              <ThemeUi.Box
                sx={{
                  display: 'grid',
                  gridGap: 1,
                  gridTemplateColumns: '1fr 1fr',
                }}
              >
                <ReactRouterDom.Link to="?login=me">
                  <ThemeUi.Button
                    variant="secondary"
                    sx={{
                      py: 0,
                    }}
                  >
                    {translate('topbar.login')}
                  </ThemeUi.Button>
                </ReactRouterDom.Link>
                <ReactRouterDom.Link to="?register=now">
                  <ThemeUi.Button
                    sx={{
                      py: 0,
                    }}
                  >
                    {translate('topbar.register')}
                  </ThemeUi.Button>
                </ReactRouterDom.Link>
              </ThemeUi.Box>
            )}
            <ThemeUi.Box
              as="button"
              sx={{
                'appearance': 'none',
                'border': 'none',
                'outline': 'none',
                'm': '0px',
                'p': '0px',
                'fontSize': [1, 2],
                'borderRadius': 3,
                'height': ['29px', '32px'],
                'width': ['29px', '32px'],
                'color': menuVisible ? 'nav-background' : 'static-white',
                'backgroundColor': menuVisible
                  ? 'static-white'
                  : 'nav-dropdown-button-background',
                'transition':
                  'background-color 125ms ease-in-out, transform 125ms ease-in-out',
                'display': 'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                'cursor': 'pointer',
                '&:active': {
                  transform: 'scale(0.95)',
                },
                '&:hover, &:focus': {
                  opacity: '0.9',
                },
              }}
              onClick={() => setMenuVisible(!menuVisible)}
            >
              <Icons.ArrowDown />
            </ThemeUi.Box>
          </ThemeUi.Box>
        </ThemeUi.Box>
      </ThemeUi.Box>
    </ThemeUi.Box>
  )
}

Herz.I18n.Loader.preload(
  ['topbar.logout', 'topbar.login', 'topbar.register'],
  Topbar
)

export default Topbar
