import * as Herz from '@rushplay/herz'
import * as Hooks from './hooks'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as Session from './session'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

function SellingPointItem(props) {
  return (
    <ThemeUi.Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: 2,
      }}
    >
      <ThemeUi.Image
        src={props.imageUrl}
        alt={props.alt}
        width="80"
        height="80"
        sx={{
          flexShrink: 0,
          height: '80px',
          width: '80px',
        }}
      />
      <ThemeUi.Box
        sx={{
          pl: 2,
          fontFamily: 'heading',
        }}
      >
        {props.content}
      </ThemeUi.Box>
    </ThemeUi.Box>
  )
}

SellingPointItem.propTypes = {
  alt: PropTypes.string,
  content: PropTypes.string,
  imageUrl: PropTypes.string,
}

export function Banner() {
  const translate = Herz.I18n.useTranslate()
  const authenticated = ReactRedux.useSelector((state) =>
    Session.isAuthenticated(state.session)
  )

  const mainBg = Hooks.useImgproxyUrl(
    translate('banner.background-image.main'),
    [
      {
        extension: 'jpg',
        resizingWidth: 800,
      },
      {
        extension: 'jpg',
        resizingWidth: 1600,
      },
    ],
    'babel-fisk'
  )
  const rightBg = Hooks.useImgproxyUrl(
    translate('banner.background-image.right'),
    [
      {
        extension: 'png',
        resizingWidth: 800,
      },
      {
        extension: 'png',
        resizingWidth: 1600,
      },
    ],
    'babel-fisk'
  )
  const leftBg = Hooks.useImgproxyUrl(
    translate('banner.background-image.left'),
    [
      {
        extension: 'png',
        resizingWidth: 800,
      },
      {
        extension: 'png',
        resizingWidth: 1600,
      },
    ],
    'babel-fisk'
  )

  return (
    <ThemeUi.Box
      sx={{
        'backgroundImage': [
          `linear-gradient(180deg, transparent 54%, rgba(13,40,88,1) 100%), url("${leftBg[0]}"), url("${rightBg[0]}"), url("${mainBg[0]}")`,
          `linear-gradient(180deg, transparent 54%, rgba(13,40,88,1) 100%), url("${leftBg[1]}"), url("${rightBg[1]}"), url("${mainBg[1]}")`,
        ],
        'backgroundSize': 'cover',
        'backgroundRepeat': 'no-repeat, no-repeat, no-repeat, no-repeat',
        'backgroundPosition': 'top center, top center, top center, top center',
        'backgroundColor': 'nav-background',
        'display': 'grid',
        'gridGap': 2,
        'placeItems': 'center',
        'pb': 3,
        '@media screen and (max-width: 500px)': {
          backgroundPosition: 'top center,38% center,60% center,top center',
        },
      }}
    >
      <ThemeUi.Box
        sx={{
          display: 'grid',
          alignContent: 'center',
          placeItems: 'center',
          gridGap: 2,
          pt: [1, 3],
        }}
      >
        <ThemeUi.Image
          src="/images/casitabi-free.svg"
          alt="logo"
          height="85"
          width="300"
          sx={{
            height: ['85px', '125px'],
            width: 'auto',
            maxWidth: '300px',
          }}
        />
        <ThemeUi.Box
          sx={{
            color: 'static-white',
            fontFamily: 'heading',
            fontSize: [3, 5],
            fontWeight: 'bold',
            textShadow: '0px 0px 7px black',
            whiteSpace: 'pre-wrap',
          }}
        >
          {translate('banner.title')}
        </ThemeUi.Box>
        {!authenticated && (
          <ReactRouterDom.Link to="?register=now">
            <ThemeUi.Button
              sx={{
                fontSize: [3, 4],
                px: ['32px', '80px'],
                py: ['8px', '10px'],
                mb: 2,
              }}
            >
              {translate('banner.cta')}
            </ThemeUi.Button>
          </ReactRouterDom.Link>
        )}
      </ThemeUi.Box>

      {!authenticated && (
        <ThemeUi.Box
          sx={{
            display: ['none', 'flex'],
            color: 'static-white',
            fontWeight: 'bold',
            fontSize: 2,
          }}
        >
          <SellingPointItem
            alt="Free to play"
            imageUrl="/images/free-to-play-icon.svg"
            content={translate('banner.selling-point.free-to-play')}
          />
          <SellingPointItem
            alt="Responsive site"
            imageUrl="/images/responsive-icon.svg"
            content={translate('banner.selling-point.responsive-site')}
          />
          <SellingPointItem
            alt="Love Girls"
            imageUrl="/images/thumb-icon.svg"
            content={translate('banner.selling-point.heart-girls')}
          />
        </ThemeUi.Box>
      )}
    </ThemeUi.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'banner.background-image.main',
    'banner.background-image.left',
    'banner.background-image.right',
    'banner.title',
    'banner.cta',
    'banner.selling-point.free-to-play',
    'banner.selling-point.responsive-site',
    'banner.selling-point.heart-girls',
  ],
  Banner
)
