import * as Herz from '@rushplay/herz'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'
import * as Urql from 'urql'

import {Image} from './image'
import PropTypes from 'prop-types'
import {QueryPopup} from './query-popup'
import querystring from 'querystring'

const getGame = `
  query Game($id: ID!) {
    game(id: $id) {
      id
      key
      title
    }
  }
`

function GameDetails(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      `game-tile.${props.gameKey}.title`,
      `game-tile.${props.gameKey}.description`,
      `game-tile.${props.gameKey}.image`,
    ],
    [props.gameKey]
  )
  return (
    <React.Fragment>
      <Image
        alt="Screenshot of game"
        extension="jpg"
        resizingWidth={425}
        width="400"
        height="210"
        imgProxyBucket="babel-fisk"
        source={translate(`game-tile.${props.gameKey}.image`)}
        sx={{
          width: '100%',
          height: 'auto',
        }}
      />
      <ThemeUi.Grid
        as="section"
        sx={{
          gridGap: 1,
          wordWrap: 'break-word',
        }}
      >
        <ThemeUi.Heading
          as="h3"
          sx={{
            fontSize: [1, 2],
            textAlign: 'center',
          }}
        >
          {translate(`game-tile.${props.gameKey}.title`)}
        </ThemeUi.Heading>
        <ThemeUi.Box
          as="p"
          sx={{
            fontSize: [0, 1],
          }}
        >
          {translate(`game-tile.${props.gameKey}.description`)}
        </ThemeUi.Box>
      </ThemeUi.Grid>
    </React.Fragment>
  )
}

GameDetails.propTypes = {
  gameKey: PropTypes.string,
}

export function GameDemo() {
  const location = ReactRouterDom.useLocation()
  const query = querystring.parse(R.drop(1, location.search))

  const [response] = Urql.useQuery({
    query: getGame,
    variables: {id: query.gameId},
  })
  const game = R.pathOr({key: ''}, ['game'], response.data)

  const translate = Herz.I18n.useTranslate()

  return (
    <QueryPopup activeQueryName="gameId">
      <ThemeUi.Box
        sx={{
          color: 'static-white',
          display: 'grid',
          gridGap: 2,
        }}
      >
        {response.fetching ? (
          <ThemeUi.Spinner sx={{justifySelf: 'center', mt: 2}} />
        ) : (
          <Suspense.Boundary>
            <GameDetails gameKey={game.key} />
          </Suspense.Boundary>
        )}
        <ThemeUi.Grid
          sx={{
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridGap: 2,
            mt: 1,
          }}
        >
          <ReactRouterDom.Link to={`/play/${query.gameId}`}>
            <ThemeUi.Button
              sx={{
                width: '100%',
              }}
              variant="secondary"
            >
              {translate('demo-popup.play')}
            </ThemeUi.Button>
          </ReactRouterDom.Link>
          <ReactRouterDom.Link to="?register=now">
            <ThemeUi.Button
              sx={{
                width: '100%',
              }}
            >
              {translate('demo-popup.register')}
            </ThemeUi.Button>
          </ReactRouterDom.Link>
        </ThemeUi.Grid>

        <ThemeUi.Link
          as={ReactRouterDom.Link}
          to="?login=me"
          variant="footerNav"
          sx={{
            textAlign: 'center',
            fontSize: 2,
          }}
        >
          {translate('demo-popup.login')}
        </ThemeUi.Link>
      </ThemeUi.Box>
    </QueryPopup>
  )
}

Herz.I18n.Loader.preload(
  ['demo-popup.login', 'demo-popup.play', 'demo-popup.register'],
  GameDemo
)

// for @loadable/component
export default GameDemo
