import * as Herz from '@rushplay/herz'
import * as Hooks from './hooks'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

export function CampaignBanner(props) {
  const translate = Herz.I18n.useTranslate()
  // This banner has the same url/campaign as the game-banner.
  // Re-using the key
  const url = translate('game-banner.redirect-url')

  const backgroundImageDesktop = Hooks.useImgproxyUrl(
    translate('campaign-banner.background-image-desktop'),
    {extension: 'jpg', resizingWidth: 1300},
    'babel-fisk'
  )
  const backgroundImageMobile = Hooks.useImgproxyUrl(
    translate('campaign-banner.background-image-mobile'),
    {extension: 'jpg', resizingWidth: 700},
    'babel-fisk'
  )

  if (!url) {
    return null
  }

  return (
    <ThemeUi.Box
      as="a"
      href={url}
      target="_blank"
      sx={{
        display: 'block',
        width: '100%',
        height: ['150px', '235px'],
        borderRadius: 0,
        backgroundImage: [
          `url(${backgroundImageMobile})`,
          `url(${backgroundImageDesktop})`,
        ],
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: ['cover', 'contain'],
        backgroundColor: 'nav-background',
        ...props.sx,
      }}
    />
  )
}

CampaignBanner.propTypes = {
  sx: PropTypes.object,
}
Herz.I18n.Loader.preload(
  [
    'campaign-banner.background-image-desktop',
    'campaign-banner.background-image-mobile',
    'game-banner.redirect-url',
  ],
  CampaignBanner
)
